import dbRequest from "../../../../services/dbRequest/dbRequest"
import { Event } from "../types/event"
import { EventRecord } from "../types/eventRecord"

const fetchEvents = async (setEnventData: React.Dispatch<React.SetStateAction<Event[]>>) => {
  try {
    const eventsRecords: EventRecord[] = await dbRequest.loadRecords("T_REC_CAL").execute() as EventRecord[]

    const allData: Event[] = []

    for (const calendar of eventsRecords) {
      const event: Event = {
        Id: calendar.ID,
        Subject: calendar.SUBJECT || "Sem título",
        StartTime: new Date(calendar.START_TIME),
        EndTime: new Date(calendar.END_TIME),
        IsAllDay: calendar.IS_ALL_DAY || false,
        IsPlanned: calendar.IS_PLANNED || false,
        CALENDAR_ID: calendar.CALENDAR_ID,
        ConferenceId: calendar.CONFERENCE_ID ?? [],
        Description: calendar.DESCRIPTION
      }

      if (calendar.RECURRENCE_RULE) event.RecurrenceRule = calendar.RECURRENCE_RULE
      if (calendar.RECURRENCE_EXCEPTION) event.RecurrenceException = calendar.RECURRENCE_EXCEPTION
      if (calendar.RECURRENCE_ID) event.RecurrenceID = calendar.RECURRENCE_ID

      allData.push(event)
    }

    setEnventData(allData)
  } catch (error) {
    console.error("Erro ao carregar coleções:", error)
  }
}

export default fetchEvents
