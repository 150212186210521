import i18n from "i18next"
import React from "react"
import * as ReactDOM from "react-dom/client"
import { Platform, Alert, AlertButton } from "react-native"
import Swal from "sweetalert2"
import WebAlert from "../views/components/WebAlert"

interface AlertProps {
  text?: string
  type?: "ok" | "confirm" | "textBox" | "select"
  title?: string
  titleType?: "info" | "error" | "warning" | "success" | "question"
  textBoxValue?: string
  initialValue?: number
  direction?: "down" | "up"
  editable?: boolean
  timeout?: number
  selectInputOptions?: string[]
  handleConfirm?: (props?: string) => string | void | Promise<void> | Promise<NodeJS.Timeout>
  handleReject?: () => void
}

interface ButtonProps {
  type: string
  handleConfirm?: () => void,
  handleReject?: () => void
}

export default async ({
  text,
  type = "ok",
  title = null,
  titleType = null,
  textBoxValue = null,
  editable = true,
  timeout = null,
  initialValue,
  direction,
  selectInputOptions,
  handleConfirm = () => null,
  handleReject = () => null
}: AlertProps) => {

  const t = (tx: string) => i18n.t(tx)

  if (Platform.OS !== "web") {
    return (
      Alert.alert(
        t(title),
        t(text),
        getMobileButtons({
          type,
          handleConfirm,
          handleReject
        })
      )
    )
  }

  const customClass = {
    confirmButton: "custom-alert-confirm-button",
    denyButton: "custom-alert-deny-button",
    title: "custom-alert-title"
  }

  switch (type) {
    case "ok":
      await Swal.fire({
        customClass: customClass,
        buttonsStyling: false,
        title: t(title),
        text: t(text),
        icon: titleType,
        allowOutsideClick: false
      }).then(({ isConfirmed, isDenied }) => {
        if (isConfirmed) handleConfirm()
        if (isDenied) handleReject()
      })
      break

    case "confirm":
      await Swal.fire({
        customClass: customClass,
        buttonsStyling: false,
        title: t(title),
        text: t(text),
        icon: titleType,
        showDenyButton: true,
        showConfirmButton: true,
        //showCancelButton: true,
        confirmButtonText: t("Confirm"),
        denyButtonText: t("Cancel"),
        allowOutsideClick: false
      }).then(({ isConfirmed, isDenied }) => {
        if (isConfirmed) handleConfirm()
        if (isDenied) handleReject()
      })
      break

    case "textBox":
      await Swal.fire({
        customClass: customClass,
        buttonsStyling: false,
        title: t(title),
        text: t(text),
        icon: titleType,
        input: "textarea",
        inputPlaceholder: t("Type your text here..."),
        inputAttributes: {
          "aria-label": "Type your message here"
        },
        showCancelButton: true,
        showConfirmButton: true,
        allowOutsideClick: false
        //@ts-ignore
      }).then(({ isConfirmed, value }) => isConfirmed && handleConfirm(value))
      break

    case "select":
      await Swal.fire({
        customClass: customClass,
        buttonsStyling: false,
        title: t(title),
        text: t(text),
        icon: titleType,
        input: "select",
        inputOptions: selectInputOptions,
        //inputPlaceholder: "Select a fruit",
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: t("Confirm"),
        denyButtonText: t("Cancel"),
        allowOutsideClick: false
        //@ts-ignore
      }).then(({ isConfirmed, value }) => isConfirmed && handleConfirm(value))
      break

    default:
      break
  }

}

function getMobileButtons({ type, handleConfirm, handleReject }: ButtonProps): AlertButton[] {
  const t = (tx: string) => i18n.t(tx)
  switch (type) {
    case "confirm":
      return [
        {
          text: t("Cancel"),
          onPress: handleReject,
          style: "destructive"
        },
        {
          text: t("Confirm"),
          onPress: handleConfirm,
          style: "cancel"
        }
      ]
    default: return [
      {
        text: "Ok",
        onPress: handleConfirm,
        style: "cancel"
      }
    ]
  }
}
