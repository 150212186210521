import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import LoadingAnimation from "../../../../components/LoadingAnimation"
import handleLogin from "../../utils/handleLogin"
import * as S from "./styles"

export default function LoginForm({ navigation }) {
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [focusedInput, setFocusedInput] = useState(null)

  const { t } = useTranslation()

  async function handleSubmit(event) {
    setIsLoggingIn(true)

    event.preventDefault()

    const email = event.target.elements.email.value
    const password = event.target.elements.password.value

    await handleLogin({ email, password, navigate: navigation.navigate })

    setIsLoggingIn(false)
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <S.Container>
        <S.InputContainer>
          <S.InputIcon name="mail" isFocused={focusedInput === "email"} />
          <S.Input
            placeholder={t("Enter your email")}
            type="email"
            name="email"
            required
            onFocus={() => setFocusedInput("email")}
            onBlur={() => setFocusedInput(null)}
          />
        </S.InputContainer>

        <S.InputContainer>
          <S.InputIcon name="lock" isFocused={focusedInput === "password"} />
          <S.Input
            placeholder={t("Enter your password")}
            type="password"
            name="password"
            required
            onFocus={() => setFocusedInput("password")}
            onBlur={() => setFocusedInput(null)}
          />
        </S.InputContainer>

        <S.LoginButton
          type="submit"
          disabled={isLoggingIn}
          style={{
            backgroundColor: isLoggingIn ? "#888888" : undefined,
            pointerEvents: isLoggingIn ? "none" : undefined,
          }}
        >
          {isLoggingIn ? <LoadingAnimation color="#FFFFFF" size="medium" /> : t("Log in")}
        </S.LoginButton>
      </S.Container>
    </form>
  )
}
