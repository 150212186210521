import dbRequest from "../../../../services/dbRequest/dbRequest"
import allRequiredFieldsFilled from "../../../../services/registration/allRequiredFieldsFilled"
import runTriggers from "../../../../services/registration/runTriggers"
import showAlert from "../../../../services/showAlert"
import { gridObserver } from "../../Grid"
import existingIDValidation from "./save/existingIDValidation"
import manualIdValidation from "./save/manualIdValidation"
import populateDetailTabsPath from "./save/populateDetailTabsPath"
import populateModels from "./save/populateModels"
import userScreenValidation from "./save/userScreenValidation"

const handleSave = (props) => {
  const isIdValid = manualIdValidation({ ...props })
  if (!isIdValid) return

  showAlert({
    type: "confirm",
    text: "Do you want to save the record?",
    title: "Record saving",
    handleConfirm: () => saveRecord({ ...props })
  })
}

export default handleSave

export async function saveRecord(props) {
  const { models, entity, setUpdateForm, changeLoadingModalVisibility } = props

  changeLoadingModalVisibility()

  //Verifica se todos os campos obrigatórios foram preenchidos
  const areAllRequiredFieldsFilled = await allRequiredFieldsFilled(props)
  if (!areAllRequiredFieldsFilled) return changeLoadingModalVisibility()

  //Verifica se o ID já existe
  const doesIdExists = await existingIDValidation(props)
  if (doesIdExists) return changeLoadingModalVisibility()

  //Verifica se o usuário é válido
  const isUserValid = await userScreenValidation(props) //Migrate to user screen
  if (!isUserValid) return changeLoadingModalVisibility()

  const tableData = models._REGISTRATION_DATA[entity]

  tableData.state = "BEFORE"

  //Verifica se todos os triggers são válidos
  const areAllTriggersValid = await runTriggers(models, entity)
  if (!areAllTriggersValid) return changeLoadingModalVisibility()

  const response = await dbRequest
    .saveRecords(entity)
    .record(models[entity])
    .execute() as any
  if (response.error) {
    changeLoadingModalVisibility()
    return showAlert({
      text: response.error,
      title: "Error",
      titleType: "error"
    })
  } else {
    models[entity].populate(response)
  }
  gridObserver.notify(null)
  //Atualiza o path dos detailTabs
  await populateDetailTabsPath(props)

  populateModels(props)

  await runTriggers(models, entity)
  changeLoadingModalVisibility()
  showAlert({
    text: "Record saved successfully",
    title: "Record saving",
    titleType: "success"
  })

  setUpdateForm((s) => !s)
}

