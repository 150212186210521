import { MaterialCommunityIcons } from "@expo/vector-icons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import LoginData from "../../../../../models/loginData"
import Navigation from "../../../../../models/navigation"
import RuntimeConfiguration from "../../../../../models/runtimeConfiguration"
import showAlert from "../../../../../services/showAlert"
import { defaultTheme } from "../../../../../styles/themes/default"
import CloseButton from "../../../CloseButton/index.web"
import ModalWrapper from "../../../Modal"
import CodeRunnerModal from "../CodeRunnerModal"
import * as S from "./styles"

export default function MoreOptionsModal({ isVisible, setIsVisible }) {
  const { t } = useTranslation()

  //@ts-ignore
  const isMusaDev = RuntimeConfiguration.getUserMddData().DEV
  const isBranchDev = LoginData.getLoginData().DEV

  const canPressButton = isMusaDev || isBranchDev

  const [modalVisible, setModalVisible] = useState(false)

  const dashboardProps = {
    tabName: t("Dashboard Builder"),
    component: "DashboardsBuilder",
    transaction: "DBCRT",
    adminMode: (isAdminMode) => RuntimeConfiguration.setDashboardBuilderAdminMode(isAdminMode)
  }

  const screenProps = {
    tabName: t("Screen Builder"),
    component: "ScreenBuilder",
    transaction: "SCREEN",
    adminMode: (isAdminMode) => RuntimeConfiguration.setIsDeveloperConfigurationMode(isAdminMode)
  }

  const handleNavigation = (type = {}) => {
    isMusaDev
      ? showAlert({
        type: "select",
        title: t("Personalization type"),
        text: t("Please select the type of personalization you want:"),
        selectInputOptions: [t("Data dictionary"), t("Branch")],
        handleConfirm: (value) => {
          // 0: Data dictionary, 1: Branch
          if (value === "0") navigate({
            modifyDD: true,
            navigationParams: type
          })
          else navigate({ navigationParams: type })

          setIsVisible(false)
        }
      })
      : navigate({ navigationParams: type })
  }

  const navigate = ({ modifyDD = false, navigationParams }) => {
    const { component, tabName, transaction, adminMode } = navigationParams

    adminMode(modifyDD)

    Navigation.closeTab(tabName)

    setTimeout(() => {
      Navigation.createTab({
        component,
        tabName,
        params: { transaction }
      })

      if (isVisible) setIsVisible(false)
    }, 100)
  }

  const closeModal = () => { setModalVisible(false) }

  return (
    <ModalWrapper isVisible={isVisible}>
      <S.Container>
        <S.ModalTitle>{t("More options")}</S.ModalTitle>

        <CloseButton callbackFunction={() => setIsVisible(s => !s)} />

        <S.ButtonsContainer>
          <S.OptionButton onClick={() => handleNavigation(screenProps)} canPressButton={canPressButton} disabled={!canPressButton}>
            <MaterialCommunityIcons
              name="monitor-edit"
              size={50}
              color={defaultTheme["gray-700"]} style={{ position: "absolute" }}
            />
            <S.OptionTitle>{t("Screen Builder")}</S.OptionTitle>
          </S.OptionButton>

          <S.OptionButton onClick={() => handleNavigation(dashboardProps)} canPressButton={canPressButton} disabled={!canPressButton}>
            <MaterialCommunityIcons
              name="chart-box-plus-outline"
              size={50}
              color={defaultTheme["gray-700"]}
            />
            <S.OptionTitle>{t("Dashboard Builder")}</S.OptionTitle>
          </S.OptionButton>

          <S.OptionButton onClick={() => {
            setModalVisible(true)
            setIsVisible(false)
          }} canPressButton={canPressButton} disabled={!canPressButton}>
            <MaterialCommunityIcons
              name="code-braces"
              size={50}
              color={defaultTheme["gray-700"]} style={{ position: "absolute" }}
            />
            <S.OptionTitle>{t("Code runner")}</S.OptionTitle>
          </S.OptionButton>
        </S.ButtonsContainer>
      </S.Container>

      <ModalWrapper isVisible={modalVisible}>
        <CodeRunnerModal closeModal={closeModal} />
      </ModalWrapper>
    </ModalWrapper>
  )
}
