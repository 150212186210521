/* eslint-disable no-fallthrough */
import { addClass, removeClass } from "@syncfusion/ej2-base"
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons"
import { ItemDirective, ItemsDirective, ToolbarComponent } from "@syncfusion/ej2-react-navigations"
import React, { Fragment } from "react"
import "../../styles.css"
import LoginData from "../../../../../models/loginData"
import dbRequest from "../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../services/showAlert"
import fetchEvents from "../../utils/fetchEvents"

export default function Toolbar({ scheduleObj, workWeekObj, resourceObj, setEventData, scheduleSettings, setScheduleSettings }) {

  const btnClick = () => {
    const settingsPanel = document.querySelector(".overview-content .right-panel")
    if (settingsPanel.classList.contains("hide")) {
      removeClass([settingsPanel], "hide")
      workWeekObj.current.refresh()
      resourceObj.current.refresh()
    }
    else addClass([settingsPanel], "hide")

    scheduleObj.current.refreshEvents()
  }

  const getEventData = () => {
    const date = scheduleObj.current.selectedDate
    return {
      Id: scheduleObj.current.getEventMaxID(),
      Subject: "",
      StartTime: new Date(date.getFullYear(), date.getMonth(), date.getDate(), new Date().getHours(), 0, 0),
      EndTime: new Date(date.getFullYear(), date.getMonth(), date.getDate(), new Date().getHours() + 1, 0, 0),
      Location: "",
      Description: "",
      IsAllDay: false,
      CalendarId: LoginData.getLoginData().ID
    }
  }

  const onToolbarItemClicked = async (args) => {

    switch (args.item.tooltipText) {
      case "Day":
        setScheduleSettings((prevState) => ({
          ...prevState,
          selectedView: scheduleSettings.isTimelineView ? "TimelineDay" : "Day"
        }))
        break
      case "Week":
        setScheduleSettings((prevState) => ({
          ...prevState,
          selectedView: scheduleSettings.isTimelineView ? "TimelineWeek" : "Week"
        }))
        break
      case "WorkWeek":
        setScheduleSettings((prevState) => ({
          ...prevState,
          selectedView: scheduleSettings.isTimelineView ? "TimelineWorkWeek" : "WorkWeek"
        }))
        break
      case "Month":
        setScheduleSettings((prevState) => ({
          ...prevState,
          selectedView: scheduleSettings.isTimelineView ? "TimelineMonth" : "Month"
        }))
        break
      case "Year":
        setScheduleSettings((prevState) => ({
          ...prevState,
          selectedView: scheduleSettings.isTimelineView ? "TimelineYear" : "Year"
        }))
        break
      case "Agenda":
        setScheduleSettings((prevState) => ({
          ...prevState,
          selectedView: "Agenda"
        }))
        break
      case "New Event":
        scheduleObj.current.openEditor(getEventData(), "Add", true)
        break
      case "New Recurring Event":
        scheduleObj.current.openEditor(getEventData(), "Add", true, 1)
        break
      case "Refresh":
        scheduleObj.current.showSpinner()
        await fetchEvents(setEventData, scheduleObj)
        scheduleObj.current.hideSpinner()
        break
      case "Save Settings":
        showAlert({
          titleType: "question",
          title: "Save Settings",
          text: "Do you want to save the settings?",
          type: "confirm",
          handleConfirm: async () => {
            try {
              const settingsObj = {
                ...scheduleSettings,
                resourcesGroups: scheduleSettings.resourcesGroups.map(item => item.name)
              }

              await dbRequest
                .saveRecords("/T_DTD_TAB/T_REC_CAL/T_DTD_FLT/")
                .record({
                  ID: LoginData.getLoginData().ID,
                  scheduleSettings: settingsObj
                })
                .execute()

              showAlert({
                titleType: "success",
                text: "Settings saved successfully",
                title: "Success"
              })

            } catch (error) {
              showAlert({
                titleType: "error",
                text: error,
                title: "Failed to save"
              })
            }
          }
        })

        break
      case "Settings":
        btnClick()
        break
      default:
        break
    }
  }

  const timelineTemplate = () => {
    const onChange = (args) => {
      setScheduleSettings((prevState) => ({
        ...prevState,
        isTimelineView: args.checked
      }))
    }

    return (<div className='template'>
      <label>
        <div className='icon-child'>
          <CheckBoxComponent id='timeline_views' checked={scheduleSettings.isTimelineView} change={onChange} />
        </div>
        <div className='text-child'>Timeline Views</div>
      </label>
    </div>)
  }

  const groupTemplate = () => {
    const onChange = async (args) => {
      scheduleObj.current.group.resources = args.checked ? scheduleSettings.resourcesGroups.map(item => item.name) : []
      setScheduleSettings((prevState) => ({
        ...prevState,
        isGroupingView: args.checked
      }))
    }

    return (
      <div className='template'>
        <label>
          <div className='icon-child'>
            <CheckBoxComponent
              id='grouping'
              checked={scheduleSettings.isGroupingView}
              change={onChange} />
          </div>
          <div className='text-child'>Grouping</div>
        </label>
      </div>
    )
  }

  const gridlineTemplate = () => {
    const onChange = (args) => {
      scheduleObj.current.timeScale.enable = args.checked
      setScheduleSettings((prevState) => ({
        ...prevState,
        enableGridline: args.checked
      }))
    }
    return (
      <div className='template'>
        <label>
          <div className='icon-child'>
            <CheckBoxComponent id='timeSlots' checked={scheduleSettings.enableGridline} change={onChange} />
          </div>
          <div className='text-child'>Gridlines</div>
        </label>
      </div>)
  }

  const autoHeightTemplate = () => {
    const onChange = (args) => {
      scheduleObj.current.rowAutoHeight = args.checked
      setScheduleSettings((prevState) => ({
        ...prevState,
        rowAutoHeight: args.checked
      }))
    }
    return (<div className='template'>
      <label>
        <div className='icon-child'>
          <CheckBoxComponent id='row_auto_height' checked={scheduleSettings.rowAutoHeight} change={onChange} />
        </div>
        <div className='text-child'>Row Auto Height</div>
      </label>
    </div>)
  }

  return (
    <Fragment>
      <ToolbarComponent
        id='toolbarOptions'
        cssClass='overview-toolbar'
        width='100%'
        height={70}
        overflowMode='Scrollable'
        scrollStep={100}
        clicked={onToolbarItemClicked}
        locale="pt"
      >
        <ItemsDirective>
          <ItemDirective prefixIcon='e-icons e-plus' tooltipText='New Event' text='New Event' tabIndex={0} />
          <ItemDirective prefixIcon='e-icons e-refresh' tooltipText='Refresh' text='Refresh' tabIndex={0} />
          <ItemDirective type='Separator' />
          <ItemDirective prefixIcon='e-icons e-day' tooltipText='Day' text='Day' tabIndex={0} />
          <ItemDirective prefixIcon='e-icons e-week' tooltipText='Week' text='Week' tabIndex={0} />
          <ItemDirective prefixIcon='e-icons e-week' tooltipText='WorkWeek' text='WorkWeek' tabIndex={0} />
          <ItemDirective prefixIcon='e-icons e-month' tooltipText='Month' text='Month' tabIndex={0} />
          <ItemDirective prefixIcon='e-icons e-month' tooltipText='Year' text='Year' tabIndex={0} />
          <ItemDirective prefixIcon='e-icons e-agenda-date-range' tooltipText='Agenda' text='Agenda' tabIndex={0} />
          <ItemDirective tooltipText='Timeline Views' text='Timeline Views' template={timelineTemplate} />
          <ItemDirective type='Separator' />
          <ItemDirective tooltipText='Grouping' text='Grouping' template={groupTemplate} />
          <ItemDirective tooltipText='Timme Slots' text='Timme Slots' template={gridlineTemplate} />
          <ItemDirective tooltipText='Auto Fit Rows' text='Auto Fit Rows' template={autoHeightTemplate} />
          <ItemDirective type='Separator' />
          <ItemDirective prefixIcon='e-icons e-settings' tooltipText='Settings' text='Settings' tabIndex={0} align="Right" />
          <ItemDirective type='Separator' />
          <ItemDirective prefixIcon='e-icons e-save' tooltipText='Save Settings' text='Save Settings' tabIndex={0} align="Left" />
        </ItemsDirective>
      </ToolbarComponent>
    </Fragment>
  )
}
