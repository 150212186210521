import { Platform } from "react-native"
import models from "../models"
import LoginData from "../models/loginData"
import Navigation from "../models/navigation"
import Permissions from "../models/permissions"
import dbAccess from "../services/dbRequest/dbAccess/"
import userConfiguration from "../services/dbRequest/userConfiguration"
import showAlert from "../services/showAlert"
import navigationDecorator from "./decorators/navigationDecorator"
import OrderComposerController from "./OrderComposerController"

export default class MenuController {
  constructor() {
    navigationDecorator(this)
  }

  async handleButtonPress(button, update, closeMenu) {
    const {
      SUB_MENUS,
      T_DTD_LNC,
      OPEN,
      TYPE,
      ATTRIBUTE,
      TRANSACTION,
      DESCRIPTION,
      MASTERID
    } = button

    const hasButtons = SUB_MENUS?.length > 0
    const hasLauncher = T_DTD_LNC && Object.values(T_DTD_LNC).length > 0

    if (!hasButtons && !hasLauncher && MASTERID === 0) return

    if (hasButtons || hasLauncher) {
      button.OPEN = !OPEN ?? false
      update((state) => !state)
      return
    }

    closeMenu()

    const controller =
      TYPE === "OrderComposer" ? new OrderComposerController() : null
    if (controller) {
      await controller.initialize()
      controller.tabName = DESCRIPTION
    }

    Permissions.setCurrentRoutine(TRANSACTION)

    if (TYPE === "Report") {
      const navigation = Navigation.getNavigation()
      return navigation.push("Root", {
        screen: "Report",
        params: {
          attribute: ATTRIBUTE,
          controller: controller,
          transaction: TRANSACTION
        }
      })
    }

    Navigation.createTab({
      component: TYPE,
      params: {
        attribute: ATTRIBUTE,
        controller: controller,
        transaction: TRANSACTION
      },
      tabName: DESCRIPTION
    })
  }

  resetMenuState(menuOptions, callback) {
    this.resetLayers(menuOptions)
    callback()
  }

  resetLayers(buttons) {
    for (const button of buttons) {
      button.OPEN = false
      if (typeof button.SUB_MENUS === "object")
        this.resetLayers(button.SUB_MENUS)
    }
  }

  getIconName(button) {
    const hasButtons = button?.SUB_MENUS?.length > 0
    const hasLauncher =
      button?.T_DTD_LNC && Object.values(button.T_DTD_LNC).length > 0

    if (hasButtons || hasLauncher)
      return button.OPEN ? "arrow-drop-down" : "arrow-right"

    return null
  }

  handleLogout() {
    showAlert({
      titleType: "question",
      type: "confirm",
      title: "You are about to exit the application.",
      text: "Are you sure you want to continue?",
      handleConfirm: async () => {
        window.location.reload()
      }
    })
  }

  async changeBranch(branch, callback) {
    const { label, value } = branch

    showAlert({
      text: "Do you want to change branches?",
      type: "confirm",
      title: Platform.OS === "web" ? "This will close all tabs" : "",
      titleType: "warning",
      handleConfirm: async () => {
        await this.updateToken(value)

        Navigation.closeTabs()
        callback()

        await Permissions.initialize()
        await models.prepareClone()

        showAlert({
          text: `${label}`,
          title: "Branch changed",
          titleType: "success"
        })
      }
    })
  }

  async updateToken(newBranch) {
    await dbAccess.connectBranch(newBranch)

    const { ID } = LoginData.getLoginData()
    await userConfiguration.setBranchData(ID)
  }
}
