import { AntDesign } from "@expo/vector-icons"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../styles/themes/default"
import * as S from "./styles"

export default function SuggestionsList({
  suggestions,
  onSelectSuggestion,
  selectedIndex
}) {
  const { t } = useTranslation()

  const suggestionRefs = useRef([])

  useEffect(() => {
    if (suggestionRefs.current[selectedIndex]) {
      suggestionRefs.current[selectedIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      })
    }
  }, [selectedIndex])

  return (
    <S.SuggestionsWrapper>
      {suggestions.map((suggestion, index) => (
        <S.SuggestionItem
          ref={(el) => (suggestionRefs.current[index] = el)}
          selectedIndex={selectedIndex === index}
          key={index}
          onClick={() => {
            onSelectSuggestion(suggestion)
          }}
        >
          {t(suggestion.DESCRIPTION)}
          <AntDesign
            name="caretright"
            size={12}
            color={defaultTheme["gray-500"]}
            style={{
              position: "absolute",
              right: 10
            }}
          />
        </S.SuggestionItem>
      ))}
    </S.SuggestionsWrapper>
  )
}
