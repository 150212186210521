/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from "react"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"
import ActionButton from "../components/ActionButton"
import DescriptionInput from "../components/Inputs/Description"
import IdInput from "../components/Inputs/Id"
import ImageInput from "../components/Inputs/Image"
import LoadingModal from "../components/LoadingModal"
import MoreOptionsButton from "../components/MoreOptionsButton"
import { toolbarIcons } from "../utils/icons"
import * as S from "./styles"

export default function FormToolbar({
  entity,
  toggleMode,
  models,
  functions,
  setUpdateForm,
  updateForm,
  gridRef,
  detailTabPath,
  screenType
}) {
  const [loadingModalVisible, setLoadingModalVisible] = useState(false)

  const { handleInsert, handleUndo, handleCopy, handleDelete, handleSave } = functions
  const tableData = models._REGISTRATION_DATA[entity]
  const { type, fields, operation, permissions, records } = tableData
  const { CREATE, UPDATE, DELETE } = permissions
  const isHierarchicalScreen = type === "Hierarchic"

  const filteredRecords = gridRef.current?.getFilteredRecords ? gridRef.current.getFilteredRecords() : []
  const tableRecords = filteredRecords.length > 0 ? filteredRecords : records

  let currentRecordIndex = tableRecords.findIndex(record => record.ID === models[entity].ID)
  if (currentRecordIndex === -1) {
    currentRecordIndex = operation === "INS" ? tableRecords.length : 0
  }
  tableData.currentRecordIndex = currentRecordIndex

  const isLastRecord = currentRecordIndex >= tableRecords.length - 1
  const isFirstRecord = currentRecordIndex <= 0
  const canSave = CREATE && operation === "INS"
  const canUpdate = UPDATE && operation === "UPD"

  function handleChangeRecord(position) {
    if ((isFirstRecord && position === "previous") || (isLastRecord && position === "next")) return

    const newPosition = position === "next" ? currentRecordIndex + 1 : currentRecordIndex - 1
    tableData.currentRecordIndex = newPosition

    models[entity].reset()
    models[entity].populate({ ...tableRecords[newPosition] })

    const detailTabs = fields.filter(({ FIELDTYPE }) => FIELDTYPE.VALUE === "E")
    for (const table of detailTabs) {
      //@ts-ignore
      const tableID = table.ID
      const recordID = tableRecords[newPosition].ID
      tableData.detailTabsPath[tableID] =
        screenType === "registrationMaster"
          ? `${entity}/${recordID}/${tableID}`
          : `${models._REGISTRATION_DATA[tableData.masterTable].detailTabsPath[entity]}/${recordID}/${tableID}`
    }

    setUpdateForm(s => !s)
  }

  const handleRefresh = async () => {
    if (!models[entity].ID) return
    setLoadingModalVisible(true)
    const recordId = models[entity].ID
    const updatedRecord = await dbRequest.loadRecords(detailTabPath ?? entity).where("ID", "==", recordId)
      .execute() as any

    if (!updatedRecord) {
      setLoadingModalVisible(false)
      return showAlert({
        title: "Record not found",
        titleType: "error"
      })
    }

    models[entity].reset()
    models[entity].populate(updatedRecord[0])
    setUpdateForm(s => !s)
    setLoadingModalVisible(false)
  }

  const inputProps = {
    entity,
    models,
    updateForm,
    setUpdateForm
  }

  return (
    <S.Container>
      <S.ItemsContainer>
        {toggleMode && (
          <S.ReturnButton onClick={toggleMode}>
            <S.ReturnIcon />
          </S.ReturnButton>
        )}
        <ImageInput {...inputProps} />
        <IdInput {...inputProps} />
        <div style={{
          marginRight: 60,
          marginLeft: 30
        }}>
          <DescriptionInput {...inputProps} />
        </div>
        {!isHierarchicalScreen && (
          <div style={{ marginRight: 15 }}>
            <ActionButton
              action={() => handleChangeRecord("previous")}
              text="Previous record"
              disabled={isFirstRecord}
              icon={isFirstRecord ? toolbarIcons.previousAlt : toolbarIcons.previous}
              variant="white"
            />
          </div>
        )}
        <ActionButton action={handleInsert} disabled={!CREATE} text="Add new record" icon={toolbarIcons.add} />
        <ActionButton action={handleCopy} disabled={!CREATE} text="Copy record" icon={toolbarIcons.copy} />
        <ActionButton action={handleUndo} text="Undo changes" icon={toolbarIcons.undo} />
        <ActionButton
          action={() => handleDelete({ changeLoadingModalVisibility: () => setLoadingModalVisible(s => !s) })}
          disabled={!DELETE}
          text="Delete record"
          icon={toolbarIcons.delete}
        />
        <div>
          <ActionButton action={handleRefresh} disabled={!models[entity].ID} text="Refresh" icon={toolbarIcons.refresh} />
        </div>
        <div style={{ marginBottom: -3 }}>
          <ActionButton
            action={() => handleSave({ changeLoadingModalVisibility: () => setLoadingModalVisible(s => !s) })}
            disabled={!canSave && !canUpdate}
            text="Save changes"
            renderText
            icon={toolbarIcons.save}
          />
        </div>
        {!isHierarchicalScreen && (
          <div style={{ marginLeft: 15 }}>
            <ActionButton
              action={() => handleChangeRecord("next")}
              text="Next record"
              icon={isLastRecord ? toolbarIcons.nextAlt : toolbarIcons.next}
              variant="white"
              disabled={isLastRecord}
            />
          </div>
        )}
      </S.ItemsContainer>
      <S.ItemsContainer>
        <MoreOptionsButton entity={entity} models={models} gridRef={gridRef} setUpdateForm={setUpdateForm} mode="form" />
      </S.ItemsContainer>
      <LoadingModal isVisible={loadingModalVisible} />
    </S.Container>
  )
}
