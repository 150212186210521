import globalDate from "../../../../global/globalDate"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"

const handleSave = async ({ eventData, isUpdate = false }) => {

  try {
    const { Id, CALENDAR_ID, StartTime, EndTime, IsAllDay, IsPlanned, Location, Subject, RecurrenceRule, RecurrenceException, RecurrenceID, Description } = eventData

    const record = {
      CALENDAR_ID: CALENDAR_ID,
      END_TIME: new globalDate(EndTime).toISO(),
      IS_ALL_DAY: IsAllDay ?? false,
      IS_PLANNED: IsPlanned ?? false,
      LOCATION: Location ?? "",
      RECURRENCE_RULE: RecurrenceRule ?? false,
      START_TIME: new globalDate(StartTime).toISO(),
      SUBJECT: Subject,
      DESCRIPTION: Description ?? "",
      RECURRENCE_EXCEPTION: RecurrenceException ?? ""
    }

    if (RecurrenceID) record.RECURRENCE_ID = RecurrenceID

    if (isUpdate) record.ID = Id

    await dbRequest
      .saveRecords("T_REC_CAL")
      .record(record)
      .execute()

    showAlert({
      text: "Event saved successfully",
      titleType: "success"
    })
  } catch (error) {
    showAlert({
      text: "Error when trying to save event",
      titleType: "error"
    })
  }

}

export default handleSave
