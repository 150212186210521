import LoginData from "../../../../models/loginData"
import dbRequest from "../../../../services/dbRequest/dbRequest"

const fetchUserSettings = async ({ scheduleObj, setScheduleSettings }) => {

  const savedSettings = await dbRequest
    .loadRecords("T_DTD_TAB/T_REC_CAL/T_DTD_FLT")
    .where("ID", "==", LoginData.getLoginData().ID)
    .execute(r => Array.isArray(r) && r[0]?.scheduleSettings) as any

  if (savedSettings) {
    savedSettings.selectedCalendars = savedSettings.selectedCalendars || [LoginData.getLoginData().ID]
    scheduleObj.current.rowAutoHeight = savedSettings.rowAutoHeight
    scheduleObj.current.firstDayOfWeek = savedSettings.firstDayOfWeek
    scheduleObj.current.workDays = savedSettings.workDays
    scheduleObj.current.timeFormat = savedSettings.timeFormat
    scheduleObj.current.startHour = savedSettings.startHour
    scheduleObj.current.endHour = savedSettings.endHour
    scheduleObj.current.workHours = {
      start: savedSettings.workStartHour,
      end: savedSettings.workEndHour
    }
    scheduleObj.current.showWeekNumber = savedSettings.showWeekNumber
    scheduleObj.current.weekRule = savedSettings.weekRule
    scheduleObj.current.eventSettings.enableTooltip = savedSettings.enableTooltip
    scheduleObj.current.timeScale = {
      enable: savedSettings.enableGridline,
      interval: savedSettings.slotDuration,
      slotCount: savedSettings.slotInterval
    }
    scheduleObj.current.group.resources = savedSettings.resourcesGroups
    //savedSettings.resourcesGroups = savedSettings.isGroupingView ? ["Calendar Id"] : []
    setScheduleSettings(savedSettings)
  }
}

export default fetchUserSettings
