import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const SuggestionsWrapper = styled.div`
  position: absolute;
  top: 82%;
  left: 0;
  right: 0;
  max-height: 200px;
  padding-top: 10px;
  overflow-y: auto;
  background: white;
  border-radius: 0 0 10px 10px;
  border: 1px solid ${defaultTheme["gray-300"]};
  z-index: 1;
`

export const SuggestionItem = styled.div`
  padding: 12px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${defaultTheme["gray-200"]};
  color: ${({ theme }) => theme["gray-700"]};
  font-size: 14px;
  color: ${({ selectedIndex }) =>
    selectedIndex ? defaultTheme["cyan-500"] : defaultTheme["gray-700"]};

  &:hover {
    background-color: ${defaultTheme["gray-200"]};
  }
`
