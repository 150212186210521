import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-left: 60px;
  width: 85%;
  height: 100%;
  background-color: ${defaultTheme["white"]};
`

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`

export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: ${defaultTheme["gray-400"]};
  margin-bottom: 2px;
  margin-left: 12px;
  border-radius: 50%;
  border-bottom-left-radius: ${({ isOpen }) => (isOpen ? "0px" : "50%")};
  border-bottom-right-radius: ${({ isOpen }) => (isOpen ? "0px" : "50%")};
  transition: border-bottom-left-radius 0.7s ease-in-out,
    border-bottom-right-radius 0.7s ease-in-out;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${defaultTheme["white"]};
  border-radius: 50%;
  width: 18px;
  height: 18px;
`

export const DropdownList = styled.ul`
  position: absolute;
  top: 29px;
  left: 12px;
  width: 180px;
  z-index: 1;
  border-radius: 0 0 0 6px;
  list-style: none;
  margin: 0;
  max-height: ${({ isOpen }) => (isOpen ? "200px" : "0px")};
  overflow-y: ${({ tabsLength }) => (tabsLength > 5 ? "scroll" : "hidden")};
  background-color: ${defaultTheme["white"]};
  transition: max-height ${({ isOpen }) => (isOpen ? "1s" : "0.7s")} ease-in-out,
    opacity 0.5s ease-in-out;
  border: 1px solid ${defaultTheme["gray-300"]};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
`

export const DropdownListItem = styled.li`
  height: 34px;
  cursor: pointer;
  background-color: ${({ isTabSelected, theme }) =>
    isTabSelected ? theme["cyan-300"] : "#DBDADA"};
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ isTabSelected, theme }) =>
    isTabSelected ? theme["white"] : theme["gray-500"]};
`

export const TextContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  margin-right: 25px;
  font-size: 14px;
  font-weight: bold;
`

export const CloseButton = styled.button`
  background-color: ${defaultTheme["white"]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 8px;
`
