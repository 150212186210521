import { FontAwesome5 } from "@expo/vector-icons"
import React, { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../styles/themes/default"
import Tab from "./components/Tab"
import * as S from "./styles"
import handleCloseTab from "./utils/handleCloseTab"
import handleTabPress from "./utils/handleTabPress"

export default function TabsContainer({
  state,
  navigation,
  setSelectedTabName
}) {
  const tabs = state.routes

  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const openDropdown = () => {
    setIsOpen(true)
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const visibleTabs = tabs
    .filter(
      (tab) =>
        !["Overview"].includes(tab.name) && tab.params.screenType !== "Reports"
    )
    .slice(0, 6)
  const hiddenTabs = tabs
    .filter(
      (tab) =>
        !["Overview"].includes(tab.name) && tab.params.screenType !== "Reports"
    )
    .slice(6)

  const renderTabs = () => {
    return visibleTabs.map((tab, index) => {
      if (
        ["Overview"].includes(tab.name) &&
        tab.params.screenType !== "Reports"
      )
        return null
      return (
        <Tab
          key={index}
          tabIndex={index}
          tabData={tab}
          state={state}
          navigation={navigation}
          setSelectedTabName={setSelectedTabName}
        />
      )
    })
  }

  const onCloseTab = (tabData, tabIndex) => {
    handleCloseTab({
      navigation,
      state,
      tabData,
      isTabSelected: state.index === tabIndex + 7,
      setSelectedTabName
    })
  }

  const onTabPress = (tabData, tabIndex) => {
    handleTabPress({
      navigation,
      state,
      tabData,
      isTabSelected: state.index === tabIndex + 7,
      setSelectedTabName
    })
  }

  return (
    <Fragment>
      {/* <ScrollButton direction="left" {...commonProps} /> */}

      <S.Container>
        {renderTabs()}
        {hiddenTabs.length >= 1 && (
          <S.DropdownContainer
            onMouseEnter={openDropdown}
            onMouseLeave={closeDropdown}
          >
            <S.DropdownButton isOpen={isOpen && hiddenTabs.length > 0}>
              <S.IconContainer>
                <FontAwesome5
                  name="ellipsis-h"
                  size={8}
                  color={defaultTheme["gray-500"]}
                />
              </S.IconContainer>
            </S.DropdownButton>
            <S.DropdownList tabsLength={hiddenTabs.length} isOpen={isOpen}>
              {hiddenTabs.map((tab, index) => (
                <S.DropdownListItem
                  key={tab.name}
                  index={index}
                  tabsLength={hiddenTabs.length}
                  onClick={() => onTabPress(tab, index)}
                  isTabSelected={state.index === index + 7}
                >
                  <S.TextContainer>{t(tab.name)}</S.TextContainer>
                  <S.CloseButton onClick={() => onCloseTab(tab, index)}>
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 9 9"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="1"
                        y1="1"
                        x2="8"
                        y2="8"
                        stroke={defaultTheme["gray-500"]}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <line
                        x1="1"
                        y1="8"
                        x2="8"
                        y2="1"
                        stroke={defaultTheme["gray-500"]}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </S.CloseButton>
                </S.DropdownListItem>
              ))}
            </S.DropdownList>
          </S.DropdownContainer>
        )}
      </S.Container>

      {/* <ScrollButton direction="right" {...commonProps} /> */}
    </Fragment>
  )
}
