import LoginData from "../../../../models/loginData"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import evaluateFunction from "../../../../services/evaluateFunction"
import getTableFields from "../../../../services/registration/getTableFields"

const fetchGroups = async (setScheduleSettings, setCalendarCollections) => {
  try {
    const calendarFields = await getTableFields({
      entityName: "T_REC_CAL"
    }).then((response) =>
      response.filter(
        (item) => item.ID === "CALENDAR_ID"
        /*  item.FIELDTYPE.VALUE === "K" &&
         !["CREATED_BY", "UPDATED_BY"].includes(item.ID) */
      )
    )

    const resourcesGroups = await Promise.all(
      calendarFields.map(async (item) => {
        const group = {
          field: item.ID,
          title: item.T_DTD_ATR.FIELDDESCRIPTION,
          name: item.T_DTD_ATR.FIELDDESCRIPTION,
          colorField: "fieldColor",
          idField: "ID",
          textField: "DESCRIPTION",
          dataSource: [],
          groupIDField: item.ID === "PRJID" ? "PTNID" : item.ID === "CALENDAR_ID" ? "PRJID" : item.ID
        }

        group.dataSource = await fetchResourceData({
          entity: item.T_DTD_ATR.ENTITY.ID,
          filter: item.T_DTD_ATR.FILTER
        }).then((response) =>
          response.map((res) => {
            const resource = {
              ID: res.ID,
              DESCRIPTION: res.DESCRIPTION,
              fieldColor: generateRandomHexColor()
            }
            if (group.groupIDField) {
              resource[group.groupIDField] = res[group.groupIDField]?.ID
            }
            return resource
          }).sort((a, b) => a.DESCRIPTION.localeCompare(b.DESCRIPTION))
        )

        if (group.field === "CALENDAR_ID") {
          group.dataSource.push(
            {
              DESCRIPTION: "My Calendar",
              ID: LoginData.getLoginData().ID,
              fieldColor: "#00ACC1"
            },
            {
              DESCRIPTION: "Company",
              ID: RuntimeConfiguration.getCurrentBranchData().ID,
              fieldColor: "#ff7f50"
            }
          )
        }
        else {
          const withoutResource = {
            ID: "0",
            DESCRIPTION: "Nenhum",
            fieldColor: generateRandomHexColor()
          }

          if (group.groupIDField) {
            withoutResource[group.groupIDField] = "0"
          }

          group.dataSource.unshift(withoutResource)
        }

        return group
      })
    )

    //const projectsData = resourcesGroups.filter((item) => item.field === "PRJID")[0]
    //const partnersData = resourcesGroups.filter((item) => item.field === "PTNID")[0]

    setCalendarCollections(resourcesGroups.filter((item) => item.field === "CALENDAR_ID")[0].dataSource)

    /*     if (projectsData && partnersData) {
          partnersData.dataSource.forEach((partner) => {
            if (partner.ID === "0") return
            projectsData.dataSource.unshift({
              ID: `Nenhum-${partner.ID}`,
              DESCRIPTION: "Nenhum",
              PTNID: partner.ID,
              fieldColor: generateRandomHexColor()
            })
          })
        } */

    /*     resourcesGroups.map((item) => {
          if (item.field === "CALENDAR_ID") {
            const newDatasource = []
            projectsData.dataSource.forEach((project) => {
              item.dataSource.forEach((resource) => {
                newDatasource.push({
                  ...resource,
                  PRJID: project.ID
                })
              })
            })
            item.dataSource = newDatasource
          } else return item
        }) */

    resourcesGroups.sort((a, b) => {
      const priorityMap = {
        "PTNID": 1,
        "PRJID": 2,
        "CALENDAR_ID": 3
      }

      const aValue = a.field
      const bValue = b.field

      const aPriority = priorityMap[aValue] || 99
      const bPriority = priorityMap[bValue] || 99

      return aPriority - bPriority
    })

    setScheduleSettings((prevState) => ({
      ...prevState,
      resourcesGroups
    }))
  } catch (error) {
    console.error(error)
  }
}

function generateRandomHexColor() {
  const letters = "0123456789ABCDEF"
  let color = "#"
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

async function fetchResourceData({ entity, filter }) {
  const filtersArray = (await evaluateFunction(filter)) ?? []

  const records = await dbRequest
    .loadRecords(entity)
    .execute((response) =>
      filterRecords({
        filters: filtersArray,
        data: response
      })
    )

  return records
}

const filterRecords = ({ filters, data }) => {
  return data.filter((record) => {
    if (record.ID === "0") return false
    if (record.ID === LoginData.getLoginData().ID) return false
    return filters.every(({ field, operator, value }) => {
      const fieldValue = getNestedValue(record, field)
      if (fieldValue === undefined) return false
      switch (operator) {
        case "==":
          return fieldValue == value
        case "!=":
          return fieldValue != value
        case ">":
          return fieldValue > value
        case ">=":
          return fieldValue >= value
        case "<":
          return fieldValue < value
        case "<=":
          return fieldValue <= value
        case "array-contains":
          return Array.isArray(value) && value.includes(fieldValue)
        default:
          return false
      }
    })
  })
}

function getNestedValue(obj, path) {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj)
}

export default fetchGroups
