import Navigation from "../../models/navigation"
import dbRequest from "../../services/dbRequest/dbRequest"
import EmailBuilder from "../../services/emailBuilder"
import removeFieldsFromObject from "../../services/removeFieldsFromObject"
import showAlert from "../../services/showAlert"
import { gridObserver } from "../../views/components/Grid"
import handleCancelOrder from "../../views/screens/OrderComposerScreen/OrderSideBar/utils/handleCancelOrder"
import ReportController from "../ReportController"

export default class OrderItemsController {

  constructor(params) {

    this.OrderComposerController = params.orderComposerController

    this.modelsTemplate = params.models()
    this.models = () => this.modelsTemplate

    this.saved = params.saved
    this.screenName = params.name
    this.collectionName = params.collectionName
    this.ItemsHeaderFields = params.ItemsHeaderFields
    this.customFields = params.customFields
  }

  async getRecords() {
    let request = dbRequest.loadRecords(`T_MOV_HDR/${this.models().T_MOV_HDR.ID}/T_MOV_ITE`)
    return await request.execute()
  }

  async deleteItem(item) {

    await dbRequest
      .deleteCollection(`T_MOV_HDR/${this.models().T_MOV_HDR.ID}/T_MOV_ITE/${item.ID}/T_MOV_TAX`)
      .execute()

    const newArray = this.models().T_MOV_ITE.filter(record => record.ID != item.ID)
    await dbRequest
      .deleteRecords(`T_MOV_HDR/${this.models().T_MOV_HDR.ID}/T_MOV_ITE`)
      .record(item)
      .execute()

    this.models().T_MOV_ITE = newArray

    this.updateList(s => !s)
  }

  async updateOBSERVATION(str) {
    this.models().T_MOV_HDR.COMMENTS = str
    await dbRequest
      .saveRecords("T_MOV_HDR")
      .record(removeFieldsFromObject({ ...this.models().T_MOV_HDR }))
      .execute()
  }

  startChangeScreen = (i, setIsLoading) => {
    return showAlert({
      type: "confirm",
      text: "Do you really want to finalize the order?",
      title: "Order finalization",
      handleConfirm: () => this.OrderComposerController.changeScreen(i, this, setIsLoading),
      handleReject: () => null
    })
  }

  listServiceName = () => "queryExecuter"
  listEntityName = () => "T_MOV_ITE"

  async saveValues() {

    await this.updateSTATUS()
    /* 
        if (!this.models().T_SET_TNS.ORDERREPORT?.ID) {
          switch (this.models().T_SET_TNS.PRINTTYPE?.VALUE) {
            case "A":
              return showAlert({
                type: "confirm",
                title: "Order report",
                text: "Would you like to view the order report?",
                handleConfirm: async () => {
                  this.openReport()
                  this.verifyIfSendEmail()
                }
              })
            case "P":
              this.openReport()
              this.verifyIfSendEmail()
              break
            default: this.verifyIfSendEmail()
              break
          }
        } */
  }

  async verifyIfSendEmail() {
    switch (this.models().T_SET_TNS?.SENDTYPE?.VALUE) {
      case "A": // Ask to send
        return showAlert({
          type: "confirm",
          title: "PDF sending",
          text: "Do you want to send the order as a PDF via email?",
          handleConfirm: async () => {
            await this.sendEmail()
          }
        })
      case "F": // Automatic
        await this.sendEmail()
        break
      default:
        showAlert({
          type: "confirm",
          text: "Do you want to add a new order?",
          title: "Order submitted successfully",
          titleType: "success",
          handleConfirm: async () => {
            //await this.OrderComposerController.reset()
            handleCancelOrder(Navigation.getNavigation(), this)
            await this.updateList()
          }
        })
        break
    }
  }

  async sendEmail() {
    try {
      let email = this.models().T_REC_PTN.EMAIL

      if (this.models().T_SET_TNS.COPY) email = email + `;${this.models().T_MOV_HDR.SELLERID.EMAIL}`

      await EmailBuilder.addEmail({
        INCLUSIONDATE: new globalDate().toISO(),
        STATUS: "P",
        SENDERID: this.models().T_SET_TNS.EMAILSENDID.ID,
        EMAIL: email,
        SUBJECT: `${this.models().T_SET_TNS.SUBJECT} ${this.models().T_MOV_HDR.ID}.`,
        MESSAGE: `${this.models().T_SET_TNS.MESSAGE}`,
        ROUTINE: "OrderComposer",
        ROUNTINEKEY: this.models().T_MOV_HDR.ID
      })
        .addAttachment({
          NAME: `Order N° ${this.models().T_MOV_HDR.ID} `,
          ATTACHMENT: await ReportController.generate(this.models().T_SET_TNS.ORDERREPORT.ID, { ID: this.models().T_MOV_HDR.ID })
        })
        .send()

      showAlert({
        text: "Email sent to the message queue",
        title: "Email sending",
        timeout: 3
      })
    }
    catch (e) { console.log(e) }
  }

  async openReport() {
    ReportController.generatePDF(await ReportController.generate(this.models().T_SET_TNS.ORDERREPORT.ID, { ID: this.models().T_MOV_HDR.ID }))
  }

  async updateSTATUS() {
    this.models().T_MOV_HDR.STATUS = {
      LABEL: "Closed",
      VALUE: "C"
    }
    await dbRequest
      .saveRecords("T_MOV_HDR")
      .record(removeFieldsFromObject({ ...this.models().T_MOV_HDR }))
      .execute(() => {
        showAlert({
          type: "ok",
          text: "Order finalized successfully",
          title: "Order finalized",
          titleType: "success"
        })
      })

    gridObserver.notify()
  }

}
