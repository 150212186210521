import dbRequest from "../../../../../../services/dbRequest/dbRequest"
import runTriggers from "../../../../../../services/registration/runTriggers"
import showAlert from "../../../../../../services/showAlert"

export default async ({ controller, entity, setIsLoading, gridRef, updateGrid }) => {
  const selectedRecords = gridRef.current.getSelectedRecords()
  const hasSelectedRecords = selectedRecords.length > 0

  if (!hasSelectedRecords)
    return showAlert({
      text: "No record selected",
      title: "Failed to delete",
      titleType: "error"
    })

  showAlert({
    type: "confirm",
    text: "Do you want to delete the selected record(s)?",
    title: "This action cannot be undone",
    titleType: "warning",
    handleConfirm: async () => {
      setIsLoading(true)

      const models = controller.models()

      for (const record of selectedRecords) {
        models._REGISTRATION_DATA[entity].operation = "DEL"
        models._REGISTRATION_DATA[entity].state = "BEFORE"

        const allTriggersValid = await runTriggers(models, entity)
        if (!allTriggersValid) {
          setIsLoading(false)
          return
        }

        const entityPath = `T_MOV_HDR/${record.ID}/T_MOV_ITE`

        const products = await dbRequest.loadRecords(entityPath).execute() as any

        for (const product of products) {
          await dbRequest
            .deleteCollection(`${entityPath}/${product.ID}/T_MOV_TAX/`)
            .execute()

          await dbRequest
            .deleteRecords(`${entityPath}`)
            .record({ ...product })
            .execute()
        }

        await dbRequest
          .deleteRecords("T_MOV_HDR")
          .record({ ...record })
          .execute()

        updateGrid()

        models._REGISTRATION_DATA[entity].state = "AFTER"
        await runTriggers(models, entity)

      }

      models[entity].reset()
      setIsLoading(false)

      showAlert({
        text: "Record(s) deleted",
        titleType: "success"
      })

    }
  })
}
